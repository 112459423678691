import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";

//@material-ui/core/*
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  withStyles,
  withTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
// import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";

//@material-ui/icons/*
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddCommentOutlinedIcon from "@material-ui/icons/AddCommentOutlined";

//images
import ImgLogo from "../images/linkchamp_logo_64.png";
import ImgMCU from "../images/microcontroller.jpg";
import ImgInverter from "../images/power_inverter.jpg";
import ImgPSWInverter from "../images/psw_inverter.jpg";

import ImgF3 from "../images/products/sn-3000c.jpg";
import ImgF2 from "../images/products/rc-p-inc.jpg";
import ImgF1 from "../images/products/sn-2000bt.jpg";
import ImgF4 from "../images/products/linkup.png";

import ImgP4 from "../images/products/sn-1500lcd.jpg";
import ImgP1 from "../images/products/sp-1500.jpg";
import ImgP2 from "../images/products/sn-1500c.jpg";
import ImgP3 from "../images/products/sp-1500c.jpg";

import ImgSNBT from "../images/sn-bt-background.jpg";

import ImgBannerRVLarge from "../images/banners/rv_large.jpeg";
import ImgBannerRVMid from "../images/banners/rv_mid.jpeg";
import ImgBannerEmVLarge from "../images/banners/emv_large.jpeg";
import ImgBannerEmVMid from "../images/banners/emv_mid.jpeg";
import ImgBannerInvLarge from "../images/banners/inv_large.jpeg";
import ImgBannerInvMid from "../images/banners/inv_mid.jpeg";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h3: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#E44A4B",
    },
  },
});

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 65,
  },
  heroContent: {
    margin: "0 auto",
    padding: `${theme.spacing.unit * 15}px 0 ${theme.spacing.unit * 15}px`,
    minHeight: 600,
  },
  heroSubUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroSubContent: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
    padding: `${theme.spacing.unit * 4}px 0`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 4,
  },
  productBG: {
    backgroundColor: theme.palette.background.default,
  },
  newsBG: {
    backgroundColor: theme.palette.background.default,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  category: {
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: `${theme.spacing.unit * 4}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 2}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  cardMedia: {
    paddingTop: "70%", // 16:9
  },
  cardMediaZoom: {
    paddingTop: "70%", // 16:9
    transform: "scale(1, 1)",
    transition: "transform 300ms ease-out",
    "&:hover": {
      transform: "scale(1.1, 1.1)",
    },
  },
  cardContent: {
    flexGrow: 1,
    minHeight: 135,
  },
  actionArea: {
    color: "#fffefe",
  },
  fab: {
    position: "absolute",
    left: theme.spacing.unit / 2,
    top: 0,
    backgroundColor: "#E44A4B",
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
  },
  chip: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

const featuredProducts = [
  {
    title: "SN-3000C",
    image: ImgF3,
    description:
      "Intelligent DC-AC® Inverter Charger with 3-Stage Charging, AC Bypass, Display & USB",
    link: "/product/sn-c-series/sn-3000c",
    productType: "Inverter",
    inquiry: true,
  },
  {
    title: "RC-P-INC",
    image: ImgF2,
    description: "Remote Control Panel for Intelligent DC-AC® Inverter Charger",
    link: "/product/rc-series/rc-p-inc",
    productType: "Remote Switch",
    inquiry: true,
  },
  {
    title: "SN-2000BT",
    image: ImgF1,
    description:
      "Intelligent DC-AC® Pure Sine Wave Inverter with Smartphone App Control",
    link: "/product/sn-bt-series/sn-2000bt",
    productType: "Inverter",
    inquiry: true,
  },
  {
    title: "LinkUP APP",
    image: ImgF4,
    description:
      "Smartphone Remote Control App on iOS/Android for Inverter & Inverter Charger",
    link: "https://linkup-get.app",
    productType: "Inverter",
    inquiry: false,
  },
];

const popularProducts = [
  {
    title: "SN-1500",
    image: ImgP4,
    description:
      "Intelligent DC-AC® Pure Sine Wave Inverter with USB & Smart Display",
    link: "/product/sn-series/sn-1500lcd",
    productType: "Inverter",
  },
  {
    title: "SN-1500C",
    image: ImgP2,
    description:
      "Intelligent DC-AC® 2-IN-1 Pure Sine Wave Inverter Charger with USB & Smart Display",
    link: "/product/sn-c-series/sn-1500c",
    productType: "Inverter",
  },
  {
    title: "SP-1500",
    image: ImgP1,
    description: "Intelligent DC-AC® Power Inverter with Microcontroller",
    link: "/product/sp-series/sp-1500",
    productType: "Inverter",
  },
  {
    title: "SP-1500C",
    image: ImgP3,
    description: "Intelligent DC-AC® 2-IN-1 Power Inverter & Charger",
    link: "/product/sp-c-series/sp-1500c",
    productType: "Inverter",
  },
];

const structuredDataOrganization = `{
  "@context": "https://schema.org",
  "@type": "Corporation",
  "name": "LinkChamp Co., Ltd.",
  "alternateName": "LinkChamp",
  "url": "https://www.dc-ac.com/",
  "logo": "https://www.dc-ac.com/images/LinkChamp_Logo_64.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "88662796386",
    "contactType": "sales",
    "areaServed": "TW",
    "availableLanguage": "en"
  },
  "sameAs": "https://www.linkedin.com/company/linkchamp"
}`;

function RVSlide(props) {
  return (
    <>
      <Hidden mdDown>
        <div
          className={props.classes.heroUnit}
          style={{
            backgroundImage: `url(${ImgBannerRVLarge})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
          }}
        >
          <div className={props.classes.heroContent}>
            <Grid container spacing={8}>
              <Grid item md={1}></Grid>
              <Grid
                item
                md={5}
                style={{
                  backgroundColor: "rgba(236, 241, 244, 0.9)", //Grey background
                  backgroundBlendMode: "overlay",
                  padding: 16,
                  borderRadius: 8, // Add rounded corners,
                  flex: "0 0 400px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  color="textPrimary"
                  gutterBottom
                  style={{ whiteSpace: "pre-line", color: "#000000" }}
                >
                  {props.item.name}
                </Typography>
                <Typography
                  component="h2"
                  variant="h6"
                  color="textSecondary"
                  paragraph
                  style={{ whiteSpace: "pre-line" }}
                >
                  {props.item.description}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={32}
                >
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="secondary"
                      component={Link}
                      to="/product/sn-c-series"
                      target="_blank"
                    >
                      Inverter Charger
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="secondary"
                      component={Link}
                      to="/product/rc-series/rc-p-inc"
                      target="_blank"
                    >
                      Remote Panel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div
          className={props.classes.heroUnit}
          style={{
            backgroundImage: `url(${ImgBannerRVMid})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
          }}
        >
          <div className={props.classes.heroContent}>
            <Grid container spacing={8} alignItems="center">
              <Hidden smDown>
                <Grid item md={1}></Grid>
                <Grid
                  item
                  md={5}
                  style={{
                    backgroundColor: "rgba(236, 241, 244, 0.9)", //Grey background
                    backgroundBlendMode: "overlay",
                    padding: 16,
                    borderRadius: 8, // Add rounded corners
                    flex: "0 0 350px",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ whiteSpace: "pre-line", color: "#000000" }}
                  >
                    {props.item.name}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {props.item.description}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={16}
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/sn-c-series"
                        target="_blank"
                      >
                        Inverter Charger
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/rc-series/rc-p-inc"
                        target="_blank"
                      >
                        Remote Panel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    backgroundColor: "rgba(236, 241, 244, 0.9)", //Grey background
                    backgroundBlendMode: "overlay",
                    padding: 16,
                    borderRadius: 8, // Add rounded corners
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ whiteSpace: "pre-line", color: "#000000" }}
                  >
                    {props.item.name}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {props.item.description}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={16}
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/sn-c-series"
                        target="_blank"
                      >
                        Inverter Charger
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/rc-series/rc-p-inc"
                        target="_blank"
                      >
                        Remote Panel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </div>
        </div>
      </Hidden>
    </>
  );
}

function EmVSlide(props) {
  return (
    <>
      <Hidden mdDown>
        <div
          className={props.classes.heroUnit}
          style={{
            backgroundImage: `url(${ImgBannerEmVLarge})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
          }}
        >
          <div className={props.classes.heroContent}>
            <Grid container spacing={8}>
              <Grid item md={1}></Grid>
              <Grid
                item
                md={5}
                style={{
                  backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
                  backgroundBlendMode: "overlay",
                  padding: 16,
                  borderRadius: 8, // Add rounded corners
                  flex: "0 0 450px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  color="textPrimary"
                  gutterBottom
                  style={{ whiteSpace: "pre-line", color: "#2594f2" }} //Blue text
                >
                  {props.item.name}
                </Typography>
                <Typography
                  component="h2"
                  variant="h6"
                  color="textSecondary"
                  paragraph
                  style={{ whiteSpace: "pre-line" }}
                >
                  {props.item.description}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={32}
                >
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="primary"
                      component={Link}
                      to="/product/sn-c-series"
                      target="_blank"
                    >
                      Inverter Charger
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="primary"
                      component={Link}
                      to="/product/sn-series"
                      target="_blank"
                    >
                      Inverter
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="primary"
                      component={Link}
                      to="/product/ats-series"
                      target="_blank"
                    >
                      ATS
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div
          className={props.classes.heroUnit}
          style={{
            backgroundImage: `url(${ImgBannerEmVMid})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
          }}
        >
          <div className={props.classes.heroContent}>
            <Grid container spacing={8} alignItems="center">
              <Hidden smDown>
                <Grid item md={1}></Grid>
                <Grid
                  item
                  md={5}
                  style={{
                    backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
                    backgroundBlendMode: "overlay",
                    padding: 16,
                    borderRadius: 8, // Add rounded corners
                    flex: "0 0 400px",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ whiteSpace: "pre-line", color: "#2594f2" }} //Blue text
                  >
                    {props.item.name}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {props.item.description}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={16}
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        component={Link}
                        to="/product/sn-c-series"
                        target="_blank"
                      >
                        Inverter Charger
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        component={Link}
                        to="/product/sn-series"
                        target="_blank"
                      >
                        Inverter
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        component={Link}
                        to="/product/ats-series"
                        target="_blank"
                      >
                        ATS
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
                    backgroundBlendMode: "overlay",
                    padding: 16,
                    borderRadius: 8, // Add rounded corners
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ whiteSpace: "pre-line", color: "#2594f2" }} //Blue text
                  >
                    {props.item.name}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {props.item.description}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={16}
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        component={Link}
                        to="/product/sn-c-series"
                        target="_blank"
                      >
                        Inverter Charger
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        component={Link}
                        to="/product/sn-series"
                        target="_blank"
                      >
                        Inverter
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        component={Link}
                        to="/product/ats-series"
                        target="_blank"
                      >
                        ATS
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </div>
        </div>
      </Hidden>
    </>
  );
}

function InvSlide(props) {
  return (
    <>
      <Hidden mdDown>
        <div
          className={props.classes.heroUnit}
          style={{
            backgroundImage: `url(${ImgBannerInvLarge})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
          }}
        >
          <div className={props.classes.heroContent}>
            <Grid container spacing={8}>
              <Grid item md={4}></Grid>
              <Grid
                item
                md={4}
                style={{
                  backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
                  backgroundBlendMode: "overlay",
                  padding: 16,
                  borderRadius: 8, // Add rounded corners,
                  // flex: "0 0 600px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  color="textPrimary"
                  gutterBottom
                  style={{ whiteSpace: "pre-line", color: "#000000" }}
                >
                  {props.item.name}
                </Typography>
                <Typography
                  component="h2"
                  variant="h6"
                  color="textSecondary"
                  paragraph
                  style={{ whiteSpace: "pre-line" }}
                >
                  {props.item.description}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={32}
                >
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="secondary"
                      component={Link}
                      to="/product/sp-series"
                      target="_blank"
                    >
                      Modified Sine Wave
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      align="center"
                      color="secondary"
                      component={Link}
                      to="/product/sn-series"
                      target="_blank"
                    >
                      Pure Sine Wave
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div
          className={props.classes.heroUnit}
          style={{
            backgroundImage: `url(${ImgBannerInvMid})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
          }}
        >
          <div className={props.classes.heroContent}>
            <Grid container spacing={8} alignItems="center">
              <Hidden smDown>
                <Grid item md={3}></Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
                    backgroundBlendMode: "overlay",
                    padding: 16,
                    borderRadius: 8, // Add rounded corners
                    // flex: "0 0 500px",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ whiteSpace: "pre-line", color: "#000000" }}
                  >
                    {props.item.name}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {props.item.description}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={16}
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/sp-series"
                        target="_blank"
                      >
                        Modified Sine Wave
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/sn-series"
                        target="_blank"
                      >
                        Pure Sine Wave
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
                    backgroundBlendMode: "overlay",
                    padding: 16,
                    borderRadius: 8, // Add rounded corners
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ whiteSpace: "pre-line", color: "#000000" }}
                  >
                    {props.item.name}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {props.item.description}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={16}
                  >
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/sp-series"
                        target="_blank"
                      >
                        Modified Sine Wave
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="secondary"
                        component={Link}
                        to="/product/sn-series"
                        target="_blank"
                      >
                        Pure Sine Wave
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </div>
        </div>
      </Hidden>
    </>
  );
}

function SNBTSlide(props) {
  return (
    <div
      className={props.classes.heroUnit}
      style={{
        backgroundImage: `url(${ImgSNBT})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
      }}
    >
      <div className={props.classes.heroContent}>
        <Hidden smDown>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="flex-start"
            style={{ paddingLeft: 80 }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="textPrimary"
              gutterBottom
              style={{ whiteSpace: "pre-line", color: "#2594f2" }} //Blue text
            >
              {props.item.name}
            </Typography>
            <Typography
              component="h2"
              variant="h6"
              color="textSecondary"
              paragraph
              style={{ whiteSpace: "pre-line" }}
            >
              {props.item.description}
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={32}
            >
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  align="center"
                  color="primary"
                  component={Link}
                  to="/product/sn-bt-series"
                  target="_blank"
                >
                  Check Product
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  align="center"
                  color="primary"
                  component={Link}
                  to="/files/brochures/sn-bt.pdf"
                  target="_blank"
                >
                  Brochure
                  <GetAppIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{
              backgroundColor: "rgba(236, 241, 244, 0.8)", //Grey background
              backgroundBlendMode: "overlay",
              padding: 16,
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              align="center"
              gutterBottom
              style={{ whiteSpace: "pre-line", color: "#2594f2" }}
            >
              {props.item.name}
            </Typography>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={16}
            >
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  align="center"
                  color="primary"
                  component={Link}
                  to="/product/sn-series/sn-1500bt"
                  target="_blank"
                >
                  Check Product
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  align="center"
                  color="primary"
                  component={Link}
                  to="/files/brochures/sn-bt.pdf"
                  target="_blank"
                >
                  Brochure
                  <GetAppIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    </div>
  );
}

function SimpleSlide(props) {
  return (
    <div className={props.item.classes.heroUnit}>
      <div className={props.item.classes.heroContent}>
        <Hidden smDown>
          <Typography
            component="h2"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            <img
              src={props.item.logo}
              alt="LinkChamp Logo"
              title="LinkChamp Logo"
              loading="lazy"
            />
          </Typography>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            // color="textPrimary"
            gutterBottom
            style={{ color: "#5C5C5C" }}
          >
            {props.item.name}
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
            style={{ whiteSpace: "pre-line" }}
          >
            {props.item.description}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography component="h2" variant="h3" align="center">
            <img
              src={props.item.logo}
              alt="LinkChamp Logo"
              title="LinkChamp Logo"
              loading="lazy"
              style={{ width: 50, height: 50 }}
            />
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {props.item.name}
          </Typography>
          <Typography
            component="h2"
            variant="subtitle2"
            align="center"
            color="textSecondary"
            paragraph
            style={{ whiteSpace: "pre-line" }}
          >
            {props.item.description}
          </Typography>
        </Hidden>
      </div>
    </div>
  );
}

function Index(props) {
  const { classes } = props;
  const [news, setNews] = useState([]);

  const featuredContents = [
    {
      title: "Power Inverter",
      icon: (
        <OfflineBoltIcon
          className={classes.icon}
          color="action"
          fontSize="large"
        />
      ),
      image: ImgInverter,
      buttons: [
        <Grid item key="indexbtns2">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/product/sp-series"
          >
            SP Series (Classic)
          </Button>
        </Grid>,
        <Grid item key="indexbtns1">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/product/hp-series"
          >
            HP Series (Compact)
          </Button>
        </Grid>,
      ],
      description: (
        <div>
          <Typography
            component="h3"
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
          >
            Our <b>Power Inverter</b> - Intelligent DC-AC® series is perfect for
            providing reliable AC power from the DC battery anytime and
            anywhere.
            <br />
            With our power inverters, the user can use a variety of AC-powered
            applications on the go, at areas without regular power outlets, such
            as campsites or construction sites, on vehicles including{" "}
            <b>automotive, caravan, campervan, motorhome, RV</b> for activities
            such as{" "}
            <b>camping, recreation, emergency AC power, job site AC power.</b>{" "}
            In addition to personal usages, our dc-ac power inverters can also
            be used for business/industrial purposes such as{" "}
            <b>
              coffee cart, food truck, ambulance, ups system, and off-grid solar
              system.
            </b>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            #PowerInverter, #Inverter, #DC-ACInverter, #DCtoACInverter,
            #Off-GridInverter
          </Typography>
        </div>
      ),
    },
    {
      title: "Pure Sine Wave Inverter",
      icon: (
        <VerifiedUserIcon
          className={classes.icon}
          color="action"
          fontSize="large"
        />
      ),
      image: ImgPSWInverter,
      buttons: [
        <Grid item key="indexbtns2">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/product/sn-c-series"
          >
            SN-C Series (Charger)
          </Button>
        </Grid>,
        <Grid item key="indexbtns1">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/product/sn-series"
          >
            SN Series (Classic)
          </Button>
        </Grid>,
      ],
      description: (
        <div>
          <Typography
            component="h3"
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
          >
            <b>Pure Sine Wave Inverters</b> are a better way to provide cleaner
            current with low harmonic distortion when compared to modified sine
            wave inverters, This characteristic is crucial for powering
            equipment such as{" "}
            <b>
              medical devices, printers, laptops, digital microwaves, fridges,
              and other sensitive electronic equipment.
            </b>{" "}
            It is also widely used in mission-critical vehicles, such as
            ambulances, fire trucks, police cars, and other emergency vehicles.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <br />
            #PureSineWaveInverter, #PureSineWave, #PowerInverter, #Inverter,
            #DCtoACInverter,
          </Typography>
        </div>
      ),
    },
    {
      title: "Intelligent Microcontroller",
      icon: (
        <DeveloperBoardIcon
          className={classes.icon}
          color="action"
          fontSize="large"
        />
      ),
      image: ImgMCU,
      buttons: [
        <Grid item key="indexbtns1">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/product/"
          >
            Latest Products
          </Button>
        </Grid>,
      ],
      description: (
        <div>
          <Typography
            component="h3"
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
          >
            Having the microcontroller integrated into all of our Intelligent
            DC-AC Power Inverter, DC-DC Converter, AC-DC Battery Charger, DC-AC
            Solar Inverter, and Solar Charge Controller, we provide features
            such as{" "}
            <b>
              soft-start, temperature/load based cooling, battery type check,
              low voltage, overheat, overload, pre-alarms, auto-shutdown.
            </b>{" "}
            <br />
            In addition, the microcontroller can be easily replaced for firmware
            upgrading and the program can be customized to meet the clients’
            requirements. Customizations include{" "}
            <b>
              pre-alarm/alarm-shutdown thresholds, energy-saving thresholds,
              time-intervals, fail-restart conditions/behaviors and many more.
            </b>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            #PowerInverter, #Converter, #BatteryCharger, #SolarInverter,
            #SolarChargeController
          </Typography>
        </div>
      ),
    },
  ];

  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  // const iframeWidth = matchesXS ? 400 : matchesSM ? 350 : matchesMD ? 300 : 325;
  const iframeWidth = "100%";
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("/data/news.json"); // Assuming that News.json is in the public folder
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const jsonData = await response.json();
        setNews(jsonData.news.reverse().slice(0, 3));
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }
    fetchData();
  }, []); // Empty dependency array means this effect will run once after initial render

  // Get the current day of the week
  const dayOfWeek = new Date().getDay();
  const startIndex = dayOfWeek % 3; // Ensures index is the first 3 slides (0-2)

  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <main>
          <Helmet>
            <title>
              {/* LinkChamp - Professional Power Solutions Design Manufacturing */}
              LinkChamp - Power Inverter, Pure Sine Inverter Charger
              Manufacturer
            </title>
            <meta
              name="description"
              content="LinkChamp - Power Inverter, Pure Sine Wave Inverter Charger Manufacturer. Professional OEM/ODM Taiwan Supplier. DC-AC Power Inverter, DC-DC Converter, Battery Charger"
            />
            <meta
              name="keywords"
              content="power inverter,converter,charger,ups,power solutions,linkchamp"
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dc-ac.com/" />
            <meta
              property="og:title"
              // content="LinkChamp - Professional Power Solutions Design Manufacturing"
              content="LinkChamp - Power Inverter & Pure Sine Wave Inverter Charger Manufacturer"
            />
            <meta
              property="og:description"
              content="LinkChamp - Power Inverter, Pure Sine Wave Inverter Charger Manufacturer. Professional OEM/ODM Taiwan Supplier. DC-AC Power Inverter, DC-DC Converter, Battery Charger"
            />
            <meta
              property="og:image"
              content="https://www.dc-ac.com/images/LinkChamp-OG-Image.png"
            />
            <link rel="canonical" href="http://dc-ac.com" />
            <script type="application/ld+json">
              {structuredDataOrganization}
            </script>
          </Helmet>

          <Carousel
            autoPlay={true}
            interval={7000}
            animation={"fade"}
            timeout={300}
            navButtonsAlwaysVisible={true}
            fullHeightHover={false}
            startAt={startIndex}
          >
            <RVSlide
              key={0}
              item={{
                name: "RV POWER\nSOLUTION",
                description:
                  "DC-AC Power Integration\nwith Smartphone Control\n\nSpecialized in Caravan, Campervan, Motorhome, RVs",
              }}
              classes={classes}
            />
            <EmVSlide
              key={1}
              item={{
                name: "MISSION\nCRITICAL",
                description:
                  "Pure Sine Wave Inverter Charger with\nAC Bypass and 3-Stage Battery Charging\n\nBest Fit for Emergency Vehicle\nAmbulance, Fire Truck, Police Car",
              }}
              classes={classes}
            />
            <SNBTSlide
              key={2}
              item={{
                name: "Power Inverter\nSmartphone Control",
                description:
                  "Flagship Pure Sine Wave Bluetooth Inverter\nwith OLED Display and Quick Charge USB",
              }}
              classes={classes}
            />
            <InvSlide
              key={3}
              item={{
                name: "POWER INVERTER",
                description:
                  "Intelligent DC-AC Power Inverter\nTransforming DC battery to AC power anytime anywhere\n\nFor Car, Truck, Bus, RV, Marine...and more",
              }}
              classes={classes}
            />
            <SimpleSlide
              key={4}
              item={{
                logo: ImgLogo,
                name: "LinkChamp Co., Ltd.",
                description:
                  "The Leading Intelligent DC-AC® Power Inverter\nDesign Manufacturer from Taiwan",
                classes: classes,
              }}
            />
          </Carousel>

          <div className={classes.productBG}>
            <Typography
              component="h2"
              variant="h5"
              align="left"
              color="textSecondary"
              className={classes.category}
            >
              NEW PRODUCTS
            </Typography>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              <Grid container spacing={40}>
                {featuredProducts.map((product) => (
                  <Grid item key={product.title} xs={6} sm={4} md={3} lg={3}>
                    <Card className={classes.card}>
                      <CardActionArea
                        className={classes.actionArea}
                        component={Link}
                        to={
                          product.link.includes("http") ? `` : `${product.link}`
                        }
                        onClick={() => {
                          if (product.link.includes("http")) {
                            window.open(`${product.link}`, "_blank");
                          }
                        }}
                      >
                        <CardMedia
                          className={classes.cardMediaZoom}
                          image={product.image}
                        />
                        <Paper className={classes.fab}>
                          <Typography className={classes.actionArea}>
                            NEW
                          </Typography>
                        </Paper>
                        <CardContent className={classes.cardContent}>
                          {isWidthUp("md", props.width) ? (
                            <>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h3"
                              >
                                {product.title}
                              </Typography>
                              <Typography component="h3" color="textSecondary">
                                {product.description}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h3"
                              >
                                {product.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="h3"
                                color="textSecondary"
                              >
                                {product.description}
                              </Typography>
                            </>
                          )}
                        </CardContent>
                      </CardActionArea>
                      <div style={{ paddingRight: 16, paddingBottom: 16 }}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          spacing={8}
                        >
                          <Grid item>
                            {product.link.includes("http") ? (
                              <Button
                                size="small"
                                color="secondary"
                                onClick={() =>
                                  window.open(`${product.link}`, "_blank")
                                }
                              >
                                Details
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                color="secondary"
                                component={Link}
                                to={`${product.link}`}
                              >
                                Details
                              </Button>
                            )}
                          </Grid>
                          {product.inquiry && (
                            <Grid item>
                              <Tooltip
                                title={
                                  props.cart[product.title] !== undefined
                                    ? "Remove from Inquiry"
                                    : "Add to Inquiry"
                                }
                                aria-label="Add to Inquiry"
                              >
                                <Button
                                  size="small"
                                  color="secondary"
                                  onClick={() => {
                                    if (
                                      props.cart[product.title] !== undefined
                                    ) {
                                      props.cartCallback({
                                        name: product.title,
                                        moq: 0,
                                      });
                                    } else {
                                      props.cartCallback({
                                        img: product.image,
                                        name: product.title,
                                        moq: 100,
                                        url: product.link,
                                        spec:
                                          product.productType.includes(
                                            "Converter"
                                          ) ||
                                          product.productType.includes(
                                            "Controller"
                                          )
                                            ? 0
                                            : 230,
                                        isNewAdd: true,
                                      });
                                    }
                                  }}
                                  button="true"
                                  className={classes.compareIcon}
                                >
                                  {props.cart[product.title] !== undefined ? (
                                    <CheckBoxIcon />
                                  ) : (
                                    <AddCommentOutlinedIcon />
                                  )}
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          <Typography
            component="h2"
            variant="h5"
            align="left"
            color="textSecondary"
            className={classes.category}
          >
            POPULAR PRODUCTS
          </Typography>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            <Grid container spacing={40}>
              {popularProducts.map((product) => (
                <Grid item key={product.title} xs={6} sm={4} md={3} lg={3}>
                  <Card className={classes.card}>
                    <CardActionArea
                      className={classes.actionArea}
                      component={Link}
                      to={`${product.link}`}
                    >
                      <CardMedia
                        className={classes.cardMediaZoom}
                        image={product.image}
                      />
                      <CardContent className={classes.cardContent}>
                        {isWidthUp("md", props.width) ? (
                          <>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h3"
                            >
                              {product.title}
                            </Typography>
                            <Typography component="h3" color="textSecondary">
                              {product.description}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h3"
                            >
                              {product.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="h3"
                              color="textSecondary"
                            >
                              {product.description}
                            </Typography>
                          </>
                        )}
                      </CardContent>
                    </CardActionArea>
                    <div style={{ paddingRight: 16, paddingBottom: 16 }}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={8}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            component={Link}
                            to={`${product.link}`}
                          >
                            Details
                          </Button>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={
                              props.cart[product.title] !== undefined
                                ? "Remove from Inquiry"
                                : "Add to Inquiry"
                            }
                            aria-label="Add to Inquiry"
                          >
                            <Button
                              size="small"
                              color="secondary"
                              onClick={() => {
                                if (props.cart[product.title] !== undefined) {
                                  props.cartCallback({
                                    name: product.title,
                                    moq: 0,
                                  });
                                } else {
                                  props.cartCallback({
                                    img: product.image,
                                    name: product.title,
                                    moq: 100,
                                    url: product.link,
                                    spec:
                                      product.productType.includes(
                                        "Converter"
                                      ) ||
                                      product.productType.includes("Controller")
                                        ? 0
                                        : 230,
                                    isNewAdd: true,
                                  });
                                }
                              }}
                              button="true"
                              className={classes.compareIcon}
                            >
                              {props.cart[product.title] !== undefined ? (
                                <CheckBoxIcon />
                              ) : (
                                <AddCommentOutlinedIcon />
                              )}
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>

          <div className={classes.heroSubUnit}>
            <Typography
              component="h2"
              variant="h5"
              align="left"
              color="textSecondary"
              className={classes.category}
            >
              COMPANY STRENGTH
            </Typography>
            <div className={classes.heroSubContent}>
              {featuredContents.map((content) => (
                <Grid
                  container
                  spacing={16}
                  justify="flex-end"
                  style={{ padding: 10 }}
                  key={content.title}
                >
                  <Grid item xs={12}>
                    <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
                      <Grid container alignItems="center">
                        {content.icon}
                        <Typography
                          component="h2"
                          variant="h5"
                          align="left"
                          color="textPrimary"
                        >
                          {content.title}
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={content.image}
                    />
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className={classes.heroButtons}>
                          <Grid container spacing={16}>
                            {content.buttons.map((button) => button)}
                          </Grid>
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8} lg={8}>
                    <Typography
                      component="h3"
                      variant="body2"
                      align="left"
                      color="textSecondary"
                      paragraph
                      style={{ paddingLeft: 10 }}
                    >
                      {content.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Hidden smDown>
                    <Grid item xs={12}>
                      <div className={classes.heroButtons}>
                        <Grid container spacing={16}>
                          {content.buttons.map((button) => button)}
                        </Grid>
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>
              ))}
            </div>
          </div>

          <div className={classes.newsBG}>
            <Typography
              component="h2"
              variant="h5"
              align="left"
              color="textSecondary"
              className={classes.category}
            >
              NEWS
            </Typography>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              <Grid container spacing={40}>
                {news.map((item) => (
                  <Grid item key={item.title} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <CardActionArea
                        className={classes.actionArea}
                        component={Link}
                        to={item.link.includes("http") ? `` : `${item.link}`}
                        onClick={() => {
                          if (item.link.includes("http")) {
                            window.open(`${item.link}`, "_blank");
                          }
                        }}
                      >
                        <CardContent className={classes.cardContent}>
                          <iframe
                            src={item.embed}
                            height="450"
                            width={iframeWidth}
                            frameBorder="0"
                            allowFullScreen=""
                            title="Embedded post"
                          ></iframe>
                        </CardContent>
                      </CardActionArea>
                      <div style={{ paddingRight: 16, paddingBottom: 16 }}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          spacing={8}
                        >
                          <Grid item>
                            {item.link.includes("http") ? (
                              <Button
                                size="small"
                                color="secondary"
                                onClick={() =>
                                  window.open(`${item.link}`, "_blank")
                                }
                              >
                                Details
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                color="secondary"
                                component={Link}
                                to={`${item.link}`}
                              >
                                Details
                              </Button>
                            )}
                          </Grid>
                          <Grid item>
                            <Button
                              size="small"
                              color="secondary"
                              component={Link}
                              to="/news"
                            >
                              More
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </main>
      </React.Fragment>
    </MuiThemeProvider>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(theme)(withStyles(styles)(withWidth()(Index)));
