import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import ImgCover from "../images/cover.jpg";
import ImgIntroduction from "../images/introduction.jpg";
import ImgHistory from "../images/history.jpg";
import ImgRND from "../images/rnd.jpg";
import ImgQC from "../images/qc.jpg";
import ImgMeetOurClients from "../images/meet_our_clients.jpg";
import ImgCertificates from "../images/certificates.jpg";
import ImgCertificatesIcons from "../images/certificates_icons.png";
import { Hidden } from "@material-ui/core";

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  mainFeaturedPost: {
    // backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 5,
    marginTop: theme.spacing.unit * 10,
    backgroundImage: `url(${ImgCover})`,
    backgroundPosition: "center left",
    backgroundSize: "cover",
  },
  darkenBox: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 5}px`,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0,
    },
  },
  card: {
    // display: "flex",
    secondary: {
      main: "#E44A4B",
    },
    marginBottom: theme.spacing.unit * 5,
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      secondary: {
        main: "#E44A4B",
      },
      marginBottom: theme.spacing.unit * 5,
    },
  },
  cardDetails: {
    flex: 1,
    whiteSpace: "pre-line",
  },
  cardMedia: {
    width: "100%",
    height: theme.spacing.unit * 20,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing.unit * 30,
      marginTop: theme.spacing.unit * 2,
      marginLeft: "auto",
      marginRight: "auto",
      backgroundSize: "cover",
    },
  },
});

const featuredPosts = [
  {
    title: "Introduction",
    image: ImgIntroduction,
    description:
      "LinkChamp Co., Ltd. is a professional OEM/ODM Taiwanese manufacturer that has more than @@year@@ years of experiences specializing in DC-AC Power Inverter, DC-DC Converter, Battery Charger, Solar Inverter, and Solar Charge Controller. \n\nOur company’s mission is to deliver top quality products with customized design and manufacturing to our clients. Therefore, the company is ISO-9001 certified and all the product lineups are approved by CE and E-mark to ensure we meet the highest standard and deliver the best.",
  },
  {
    title: "History",
    image: ImgHistory,
    description:
      "LinkChamp Co., Ltd. was founded in 1993 as a power inverter OEM/ODM manufacturer. Over the past @@year@@ years, we have been continuously developing and improving our Intelligent DC-AC®  series.\n\nWe are the only manufacturer in the industry that provides the full range of microcontroller integrated power inverters - Intelligent DC-AC®  series. With our microcontroller embedded, we provide the most flexible programming customization to our clients that stands out in the industry.",
  },
  {
    title: "Research & Development",
    image: ImgRND,
    description:
      "Having microcontroller integrated into each of our Intelligent DC-AC® series, our engineering team has developed over 200+ MCU for over 110+ various inverter/converter models. With our intelligent microcontrollers, we provide the most flexible programming customization to our clients that stands out in the industry.",
  },
  {
    title: "Quality Control",
    image: ImgQC,
    description:
      "To ensure the quality of our products meets the highest standard, our QC team strictly executes inspection and testing at each stage of the production.\n\nStarting from material sourcing, QC staffs follow the IQC procedure for the incoming materials. Next, each PCB board will be tested by Automated-Optical-Inspection and In-Circuit-Test. Then, parts and products are followed by the PQC and the FQC with the Burn-in Test. Lastly, the OQC is conducted to complete the entire process and ensure the best product quality.",
  },
  {
    title: "Global Clients",
    image: ImgMeetOurClients,
    description:
      "We have the business relationship with clients around the world including Europe, Africa, Asia, Australia, North and South America. In total, over 90 countries and with several well-known branded companies and established distributors like Bosch, ThyssenKrupp, Maplin, DickSmith, ...etc",
  },
  {
    title: "Certificates",
    image: ImgCertificates,
    description:
      "Product quality is our first priority as well as the responsibility to minimize the environmental impact. Therefore, all of our product series are approved by the following certifications and regulations:",
  },
];

function Company(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.layout}>
        <main>
          <Helmet>
            <title>LinkChamp - Mission, Introduction, and Capability</title>
            <meta
              name="description"
              content={
                "LinkChamp is a professional OEM/ODM Taiwanese manufacturer that has more than " +
                (new Date().getFullYear() - 1993).toString() +
                " years of experiences specializing in Power Solutions like DC-AC Power Inverter, DC-DC Converter, Battery Charger, and Solar Charge Controller."
              }
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={window.location.host + props.match.url}
            />
            <meta
              property="og:title"
              content="LinkChamp - Mission, Introduction, and Capability"
            />
            <meta
              property="og:description"
              content={
                "LinkChamp is a professional OEM/ODM Taiwanese manufacturer that has more than " +
                (new Date().getFullYear() - 1993).toString() +
                " years of experiences specializing in Power Solutions like DC-AC Power Inverter, DC-DC Converter, Battery Charger, and Solar Charge Controller."
              }
            />
            <meta
              property="og:image"
              content="http://dc-ac.com/images/history.jpg"
            />
          </Helmet>
          {/* Main featured post */}
          <Paper className={classes.mainFeaturedPost}>
            <Grid container className={classes.darkenBox}>
              <Grid item md={6}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography
                    variant="h4"
                    component="h1"
                    color="inherit"
                    gutterBottom
                  >
                    LINKCHAMP CO., LTD.
                  </Typography>
                  <Typography variant="h5" color="inherit" paragraph>
                    “To deliver top quality products with customized design and
                    manufacturing.”
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
          {/* End main featured post */}

          <Grid container spacing={16}>
            {/* Introduction */}
            <Hidden smDown>
              <Grid item md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[0].image}
                  />
                </Card>
              </Grid>
            </Hidden>
            <Grid item key={featuredPosts[0].title} sm={12} md={9}>
              <Card className={classes.card}>
                <Hidden mdUp>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[0].image}
                  />
                </Hidden>
                <div className={classes.cardDetails}>
                  <CardContent>
                    <Typography component="h2" variant="h5">
                      {featuredPosts[0].title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {featuredPosts[0].description.replace(
                        "@@year@@",
                        (new Date().getFullYear() - 1993).toString()
                      )}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            {/* End of Introduction */}

            {/* History */}
            <Hidden smDown>
              <Grid item md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[1].image}
                  />
                </Card>
              </Grid>
            </Hidden>
            <Grid item key={featuredPosts[1].title} sm={12} md={9}>
              <Card className={classes.card}>
                <Hidden mdUp>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[1].image}
                  />
                </Hidden>
                <div className={classes.cardDetails}>
                  <CardContent>
                    <Typography component="h2" variant="h5">
                      {featuredPosts[1].title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {featuredPosts[1].description.replace(
                        "@@year@@",
                        (new Date().getFullYear() - 1993).toString()
                      )}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            {/* End of History */}

            {/* RND */}
            <Hidden smDown>
              <Grid item md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[2].image}
                  />
                </Card>
              </Grid>
            </Hidden>
            <Grid item key={featuredPosts[2].title} sm={12} md={9}>
              <Card className={classes.card}>
                <Hidden mdUp>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[2].image}
                  />
                </Hidden>
                <div className={classes.cardDetails}>
                  <CardContent>
                    <Typography component="h2" variant="h5">
                      {featuredPosts[2].title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {featuredPosts[2].description}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            {/* End of RND */}

            {/* QC */}
            <Hidden smDown>
              <Grid item md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[3].image}
                  />
                </Card>
              </Grid>
            </Hidden>
            <Grid item key={featuredPosts[3].title} sm={12} md={9}>
              <Card className={classes.card}>
                <Hidden mdUp>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[3].image}
                  />
                </Hidden>
                <div className={classes.cardDetails}>
                  <CardContent>
                    <Typography component="h2" variant="h5">
                      {featuredPosts[3].title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {featuredPosts[3].description}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            {/* End of QC */}

            {/* Global Clients */}
            <Hidden smDown>
              <Grid item md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[4].image}
                  />
                </Card>
              </Grid>
            </Hidden>
            <Grid item key={featuredPosts[4].title} sm={12} md={9}>
              <Card className={classes.card}>
                <Hidden mdUp>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[4].image}
                  />
                </Hidden>
                <div className={classes.cardDetails}>
                  <CardContent>
                    <Typography component="h2" variant="h5">
                      {featuredPosts[4].title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {featuredPosts[4].description}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            {/* End of Global Clients */}

            {/* Certificates */}
            <Hidden smDown>
              <Grid item md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[5].image}
                  />
                </Card>
              </Grid>
            </Hidden>
            <Grid item key={featuredPosts[5].title} sm={12} md={9}>
              <Card className={classes.card}>
                <Hidden mdUp>
                  <CardMedia
                    className={classes.cardMedia}
                    image={featuredPosts[5].image}
                  />
                </Hidden>
                <div className={classes.cardDetails}>
                  <CardContent>
                    <Typography component="h2" variant="h5">
                      {featuredPosts[5].title}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {featuredPosts[5].description}
                    </Typography>
                    <img
                      src={ImgCertificatesIcons}
                      style={{ width: "70%", height: "50%" }}
                      alt="certificateIcons"
                      title="Certificates"
                      loading="lazy"
                    />
                  </CardContent>
                </div>
              </Card>
            </Grid>
            {/* End of Certificates */}
          </Grid>
        </main>
      </div>
    </React.Fragment>
  );
}

Company.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Company);
